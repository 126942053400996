<template>
    <recess-modal variant="variant2" :show-modal="isModalVisible" @close="$emit('close')">
        <template slot="modal-title">
            <h3 class="u-fw-semi-bold qa-edit-duplicated-courses-title">
                {{ EditDuplicateCourse.Title }}
            </h3>
            <p class="qa-edit-duplicated-courses-description">
                {{ EditDuplicateCourse.Description }}
            </p>
        </template>

        <template slot="modal-body">
            <recess-input
                v-model="duplicatedCourse.name"
                :label-text="EditDuplicateCourse.Name.Label"
                class="w-60 qa-duplicate-course-name"
                :error-message="courseNameError"
                @input="(newValue) => setNewCourseName(newValue)"
                @blur="$v.duplicatedCourse.name.$touch()"
            />

            <recess-input
                v-model="duplicatedCourse.externalId"
                :label-text="`${EditDuplicateCourse.ExternalId.Label}*`"
                class="w-50 mt-4 qa-duplicate-course-id"
                :error-message="courseExternalIdError"
                @input="(newValue) => setNewCourseId(newValue)"
                @blur="$v.duplicatedCourse.externalId.$touch()"
            />
        </template>

        <template slot="modal-footer">
            <div class="d-flex justify-content-end mt-5">
                <recess-button
                    variant="tertiary"
                    :title="BUTTON_TEXT.cancel"
                    class="col-12 col-md-3 qa-duplicate-course-cancel-button"
                    @click.native.prevent="closeModal()"
                />
                <recess-button
                    variant="secondary"
                    :title="BUTTON_TEXT.save"
                    class="col-12 col-md-3 ml-3 qa-duplicate-course-save-button"
                    @click.native.prevent="submitHandler"
                />
            </div>
        </template>
    </recess-modal>
</template>

<script>
import { mapActions } from 'vuex'
import { getItemById, getItems, postItem } from '@/../../shared/api/SharedClient'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { BUTTON_TEXT, API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'
import { EditDuplicateCourse } from '@/../../shared/constants/constantsProvider.json'
import { isRequiredErrorMessage, isUniqueErrorMessage } from '@/../../shared/constants/validationMessageHelper.js'


export default {
    name: 'EditDuplicatedCoursePopupComponent',
    mixins: [validationMixin],
    validations: {
        duplicatedCourse: {
            name: { required },
            externalId: {
                required,
                isUniqueId() {
                    if (this.duplicatedCourse.externalId === this.originalCourse.externalId) {
                        return false
                    }

                    return true
                }
            }
        }
    },
    props: {
        isModalVisible: {
            type: Boolean,
            default: false
        },
        courseId: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            BUTTON_TEXT,
            EditDuplicateCourse,
            timeout: null,
            duplicatedCourse: {},
            originalCourse: {},
            selectedAccounts: []
        }
    },
    computed: {
        courseNameError() {
            return isRequiredErrorMessage('name', 'Naam', this.$v.duplicatedCourse.name)   
        },

        courseExternalIdError() {
            if (!this.$v.duplicatedCourse.externalId.$error) return null

            return (
                isRequiredErrorMessage('externalId', EditDuplicateCourse.ExternalId.Label, this.$v.duplicatedCourse.externalId) ||
                isUniqueErrorMessage('ID', this.$v.duplicatedCourse.externalId, 'isUniqueId' )
            ) 
        }
    },

    async mounted() {
        await this.getCourse()
        await this.getProductTargetAccounts()
    },
    beforeDestroy() {
        clearTimeout(this.timeout)
    },
    methods: {
        ...mapActions('courseModule', ['fetchCourse']),
        async getCourse() {
            try {
                const response = await getItemById(
                    process.env.VUE_APP_PROVIDER_API_URL,
                    API_CALL_URL_PATHS.courses,
                    this.courseId,
                    null,
                    true
                )
                if (!response) return

                this.originalCourse = response
                this.duplicatedCourse = JSON.parse(JSON.stringify(response))
                delete this.duplicatedCourse.id
                delete this.duplicatedCourse.source
                this.duplicatedCourse.learningMethod.planning.startMoments.forEach(startmoment => {
                    delete startmoment.id
                })

            } catch (error) {
                console.error('Something went wrong while retrieving course details', error)
            }
        },
        setNewCourseId(newValue) {
            this.duplicatedCourseName = newValue
        },
        setNewCourseName(newValue) {
            this.duplicatedCourseId = newValue
        },
        async submitHandler() {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                await this.createCourse()
            }
        },
        closeModal() {
            this.$emit('close')
        },
        async createCourse() {
            this.closeModal()
            
            // Save the course and accounts information
            localStorage.setItem('duplicatedCourse', JSON.stringify(this.duplicatedCourse))
            localStorage.setItem('selectedAccounts', JSON.stringify(this.selectedAccounts))
            localStorage.setItem('duplicatedId', JSON.stringify({courseId: this.courseId}))

            // Navigate to the duplicate page
            this.$router.push({ name: 'course-duplicate',  params: { isDuplicateCourseEvent: true }}).then(() => { this.$router.go()})
        },
        async getProductTargetAccounts() {
            try {
                const response = await getItems(
                    process.env.VUE_APP_PROVIDER_API_URL,
                    API_CALL_URL_PATHS.productTargetAccounts,
                    1,
                    99999,
                    null,
                    `productReference eq 'course:${this.courseId}'`,
                    false
                )
                if (!response) return

                this.selectedAccounts = response.items.map(
                    (selectedAccount) => selectedAccount.accountReference.split(':')[1]
                )
            } catch (error) {
                console.error(
                    'Something went wrong while retrieving the product target account list',
                    error
                )
            }
        }
    }
}
</script>
